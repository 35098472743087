import React, { useState, useEffect, useRef, useCallback } from "react";
import { models } from "powerbi-client";
import EvaButton from "../EvaButton/EvaButton";
import "./MVDDesktop.css";
import axios from "axios";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { addToast } from "../libs/redux/slices/toastReducer";
import ToastsRedux from "../components/ToastsRedux";
import useDocumentTitle from "../hooks/useDocumentTitle";

import debounce from "../utils/Debounce";
import { setSizePage } from "../libs/redux/slices/sizePageSlice";

const MVDWarroom = () => {
  const context = useOutletContext();
  useDocumentTitle(context.title);
  
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const params = useParams('dashboard_id');
  const embedRef = useRef(null);
  const navigate = useNavigate();

  const getAccessToken = async () => {
    const url = "https://genai-api.nawatech.co/powerbi/get-access-token";
    try {
      const response = await axios.post(url, {}, {
        headers: {
          "X-API-Key": "e1a38197a2c0fa11176188725b6e206dc824203f",
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (response.status == 200) {
        const data = await response.data;
        return data;
      }
    } catch (error) {
      console.error('Error fetching access token:', error);
      if(error.response.status === 401){
        dispatch(addToast({
          type: 'error',
          title: 'User session login expired',
        }));
        const timeout = setTimeout(() => {
          navigate('/login');
        }, 5000)
        return () => clearTimeout(timeout);
      }else{
        dispatch(addToast({
          type: 'error',
          title: 'Error fetching PowerBI token',
        }));
      }
    }
  };

  const getTokenPowerBI = async (accessToken) => {
    const requestBody = JSON.stringify({
      "accessLevel": "View",
      "identities": [
        {
          "datasets": [context.dataset_id],
          "roles": [context.role],
          "username": user.name,
        },
      ],
      "datasets": [
        { "id": context.dataset_id },
      ],
      "reports": [
        {
          "allowEdit": false,
          "id": context.report_id,
        },
      ],
      "lifetimeInMinutes": 50,
    });

    const response = await axios.post('https://api.powerbi.com/v1.0/myorg/GenerateToken', requestBody,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      });

    const data = await response.data;

    return data;
  }

  const embedPowerBIReport = async () => {
    try {
      const token = await getAccessToken();
      const tokenPowerBI = await getTokenPowerBI(token.access_token);
      const embedConfig = {
        type: 'report',
        tokenType: models.TokenType.Embed,
        accessToken: tokenPowerBI.token,
        embedUrl: 'https://app.powerbi.com/reportEmbed',
        id: context.report_id,
        pageName: "ReportSectionc23c5f8f65a498aa0050",
        settings: {
          filterPaneEnabled: context.filter_pane_enabled,
          navContentPaneEnabled: context.content_pane_visible,
        },
      };
      window.powerbi.embed(embedRef.current, embedConfig);
    } catch (error) {
      console.error('Error fetching embed token:', error);
      dispatch(addToast({
        type: 'error',
        title: 'Error embed PowerBI',
      }));
    }
  };

  const updateTokenPowerBI = async () => {
    try {
      const token = await getAccessToken();
      const tokenPowerBI = await getTokenPowerBI(token.access_token);
      let embedPowerBI = window.powerbi.get(embedRef.current);
      await embedPowerBI.setAccessToken(tokenPowerBI.token);
    } catch (error) {
      console.error("Failed Update token powerBI");
      dispatch(addToast({
        type: 'error',
        title: 'Failed to refresh PowerBI token',
      }));
    }
  }

  useEffect(() => {
    if (user) {
      embedPowerBIReport();
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      updateTokenPowerBI();
    }, 15 * 60 * 1000)
    return () => clearInterval(interval)
  }, []);

  const dashboardRef = useRef(null);
  
  const handleResize = useCallback(debounce(() => {
    if(!dashboardRef.current) return;
    const width = dashboardRef.current.offsetWidth;
    dispatch(setSizePage(width));
    console.log('hitted');
  }, 300), [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    window.addEventListener('load', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('load', handleResize)
    }
  }, [])

  return (
    <div className="App" ref={dashboardRef} style={{ position: "relative" }}>
      <ToastsRedux />
      <EvaButton />
      <div className="" ref={embedRef} style={{ height: '100vh', width: '100%', borderWidth: '0' }} />
    </div>
  );
};

export default MVDWarroom;
