import React, { useState, useEffect, useMemo, useRef, useCallback, act } from "react";
import {
  createDirectLine,
  createStyleSet,
  Components,
} from "botframework-webchat";

import "./App.css";
import Eva from "./assets/Eva.png";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addToast } from "./libs/redux/slices/toastReducer";
import EvaDashboard from "./EvaDashboard/EvaDashboard";

const WebChatComponent = () => {
  const dispatch = useDispatch();
  const [directLine, setDirectLine] = useState({});
  const stopRetry = useRef(0);

  const fetchDirectLineToken = useCallback(async () => {
    const URL = "https://genai-api.nawatech.co/directline/v2/token";
    const accessToken = "Bearer " + localStorage.getItem("access_token");
    try {
      const response = await axios.post(URL, {}, {
        headers: {
          Authorization: accessToken,
        },
      })
      if (response.status === 200) {
        setDirectLine(response.data);
      }
    } catch (error) {
      console.error("Error fetching Directline token:", error);
      dispatch(addToast({
        type: "error",
        title: "Failed to fetch chatbot",
      }));
    }
  }, []);

  const fetchRefreshDirectLineToken = useCallback(async () => {
    const URL = `https://genai-api.nawatech.co/directline/v2/refresh-token?prevToken=${directLine.token}`;
    try {
      const response = await axios.post(URL, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      if (response.status === 200) {
        setDirectLine(response.data);
      }
    } catch (error) {
      console.error("Error refreshing Direct Line token:", error);
      dispatch(addToast({
        type: "error",
        title: "Failed to refresh session eva",
      }));
    }
  }, [directLine])

  useEffect(() => {
    fetchDirectLineToken();
  }, [])

  useEffect(() => {
    if (directLine.token) {
      const timeOut = setTimeout(() => {
        fetchRefreshDirectLineToken();
        console.log('refreshing token');
      }, (directLine.expires_in - (directLine.expires_in * 0.3)) * 1000)
      return () => clearTimeout(timeOut);
    }
  }, [directLine])

  const directline = useMemo(() => createDirectLine({
    token: directLine.token,
  }), [directLine]);

  useEffect(() => {
    directline.connectionStatus$.subscribe(connectionStatus => {
      console.log('connectionStatus ', connectionStatus);
      if (connectionStatus === 4) {
        if (!stopRetry.current <= 2) {
          fetchDirectLineToken();
          stopRetry.current = stopRetry.current + 1;
        }
      } else if (connectionStatus === 5) {
        dispatch(addToast({
          type: "error",
          title: "Failed to connect to chatbot, please refresh page",
        }));
      }
    });
  }, [directline]);

  const adaptiveCardHostConfig = useMemo(() => (
    {
      "hostCapabilities": {
        "capabilities": null
      },
      "choiceSetInputValueSeparator": ",",
      "supportsInteractivity": true,
      "fontTypes": {
        "default": {
          "fontFamily": "Arial, Calibri, sans-serif",
          "fontSizes": {
            "small": 16,
            "default": 16,
            "medium": 16,
            "large": 16,
            "extraLarge": 16
          },
          "fontWeights": {
            "lighter": 200,
            "default": 400,
            "bolder": 600
          }
        },
        "monospace": {
          "fontFamily": "'Courier New', Courier, monospace",
          "fontSizes": {
            "small": 16,
            "default": 16,
            "medium": 16,
            "large": 16,
            "extraLarge": 16
          },
          "fontWeights": {
            "lighter": 200,
            "default": 400,
            "bolder": 600
          }
        }
      },
      "spacing": {
        "small": 3,
        "default": 8,
        "medium": 20,
        "large": 30,
        "extraLarge": 40,
        "padding": 10
      },
      "separator": {
        "lineThickness": 1,
        "lineColor": "#EEEEEE"
      },
      "imageSizes": {
        "small": 40,
        "medium": 80,
        "large": 160
      },
      "containerStyles": {
        "default": {
          "foregroundColors": {
            "default": {
              "default": "#FFFFFF",
              "subtle": "#FFFFFF",
              "highlightColors": {
                "default": "#22000000",
                "subtle": "#11000000"
              }
            },
            "dark": {
              "default": "#000000",
              "subtle": "#66000000",
              "highlightColors": {
                "default": "#22000000",
                "subtle": "#11000000"
              }
            },
            "light": {
              "default": "#000000",
              "subtle": "#66000000",
              "highlightColors": {
                "default": "#22000000",
                "subtle": "#11000000"
              }
            },
            "accent": {
              "default": "#000000",
              "subtle": "#66000000",
              "highlightColors": {
                "default": "#22000000",
                "subtle": "#11000000"
              }
            },
            "good": {
              "default": "#000000",
              "subtle": "#66000000",
              "highlightColors": {
                "default": "#22000000",
                "subtle": "#11000000"
              }
            },
            "warning": {
              "default": "#ED1C24",
              "subtle": "#66000000",
              "highlightColors": {
                "default": "#22000000",
                "subtle": "#11000000"
              }
            },
            "attention": {
              "default": "#ED1C24",
              "subtle": "#66000000",
              "highlightColors": {
                "default": "#22000000",
                "subtle": "#11000000"
              }
            }
          },
          "backgroundColor": "#303030"
        },
        "emphasis": {
          "foregroundColors": {
            "default": {
              "default": "#000000",
              "subtle": "#66000000",
              "highlightColors": {
                "default": "#22000000",
                "subtle": "#11000000"
              }
            },
            "dark": {
              "default": "#000000",
              "subtle": "#66000000",
              "highlightColors": {
                "default": "#22000000",
                "subtle": "#11000000"
              }
            },
            "light": {
              "default": "#000000",
              "subtle": "#66000000",
              "highlightColors": {
                "default": "#22000000",
                "subtle": "#11000000"
              }
            },
            "accent": {
              "default": "#000000",
              "subtle": "#66000000",
              "highlightColors": {
                "default": "#22000000",
                "subtle": "#11000000"
              }
            },
            "good": {
              "default": "#000000",
              "subtle": "#66000000",
              "highlightColors": {
                "default": "#22000000",
                "subtle": "#11000000"
              }
            },
            "warning": {
              "default": "#000000",
              "subtle": "#66000000",
              "highlightColors": {
                "default": "#22000000",
                "subtle": "#11000000"
              }
            },
            "attention": {
              "default": "#000000",
              "subtle": "#66000000",
              "highlightColors": {
                "default": "#22000000",
                "subtle": "#11000000"
              }
            }
          },
          "backgroundColor": "#303030"
        }
      },
      "actions": {
        "maxActions": 100,
        "spacing": "Default",
        "buttonSpacing": 8,
        "showCard": {
          "actionMode": "Inline",
          "inlineTopMargin": 8,
          "style": "emphasis"
        },
        "Submit": {
          "actionMode": "Inline",
          "inlineTopMargin": 8,
          "style": "positive"
        },
        "preExpandSingleShowCardAction": false,
        "actionsOrientation": "horizontal",
        "actionAlignment": "Stretch",
        "wrap": true
      },
      "adaptiveCard": {
        "allowCustomStyle": true
      },
      "imageSet": {
        "maxImageHeight": 100
      },
      "media": {
        "allowInlinePlayback": true
      },
      "factSet": {
        "title": {
          "size": "Default",
          "color": "Default",
          "isSubtle": false,
          "weight": "Bolder",
          "wrap": true
        },
        "value": {
          "size": "Default",
          "color": "Default",
          "isSubtle": false,
          "weight": "Default",
          "wrap": true
        },
        "spacing": 8
      },
      "cssClassNamePrefix": null,
      "_legacyFontType": {
        "fontFamily": "Segoe UI,Segoe,Segoe WP,Helvetica Neue,Helvetica,sans-serif",
        "fontSizes": {
          "small": 12,
          "default": 14,
          "medium": 17,
          "large": 21,
          "extraLarge": 26
        },
        "fontWeights": {
          "lighter": 200,
          "default": 400,
          "bolder": 600
        }
      }
    }
  ))

  const styleOptions = useMemo(() => ({
    paddingRegular: 10,
    // paddingWide: 10 * 2,

    backgroundColor: "#212124",
    primaryFont: "Arial",
    avatarSize: 40,
    botAvatarBackgroundColor: "white",
    botAvatarImage: Eva,
    botAvatarInitials: "EV",
    showAvatarInGroup: true,

    bubbleBackground: "#303030",
    bubbleBorderColor: "#303030",
    bubbleBorderRadius: 5,


    bubbleMaxWidth: 540, // Based off screen width = 600px
    bubbleFromUserBackground: "#28283C",
    bubbleFromUserBorderColor: "#303030",
    bubbleFromUserBorderRadius: 5,
    bubbleFromUserTextColor: "white",
    bubbleImageHeight: "40vh",
    bubbleTextColor: "white",

    sendBoxBorderRadius: 5,
    sendBoxBorderStyle: "solid",
    sendBoxBackground: "#303030",
    sendBoxButtonColor: "white",
    sendBoxPlaceholderColor: "white",
    sendBoxTextColor: "white",
    hideUploadButton: true,
    hideSendBox: false,

    timestampFormat: "absolute",
    scrollToEndButtonFontSize: 8,

    typingAnimationDuration: 86400000,
    typingAnimationHeight: 20,
    typingAnimationWidth: 64,

    adaptiveCardsParserMaxVersion: '1.5',
    cardEmphasisBackgroundColor: '#F9F9F9',
    cardPushButtonBackgroundColor: '#0063B1',
    cardPushButtonTextColor: 'White',
    richCardWrapTitle: false,
    accent: '#0063B1',
  }), []);

  return (
    <>
      {
        directLine.token && (
          <Components.Composer
            directLine={directline}
            styleOptions={styleOptions}
            adaptiveCardsHostConfig={adaptiveCardHostConfig}
          >
            <Components.AccessKeySinkSurface>
              <EvaDashboard />
              <Components.BasicWebChat className="webchat-container" />
            </Components.AccessKeySinkSurface>
          </Components.Composer>
        )
      }
    </>
  )
};

export default WebChatComponent;