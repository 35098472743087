import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  size: null,
};

const sizePageSlice = createSlice({
  name: 'sizePage',
  initialState,
  reducers: {
    setSizePage: (state, action) => {
      state.size = action.payload;
    },
  },
});

export const { setSizePage } = sizePageSlice.actions;
export default sizePageSlice.reducer;
