import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import sidebarReducer from './slices/sidebarSlices';
import toastReducer from './slices/toastReducer';
import sizePageReducer from './slices/sizePageSlice';
const rootReducer = combineReducers({
  auth: authReducer,
  sidebar: sidebarReducer,
  toast: toastReducer,
  sizePage: sizePageReducer
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
